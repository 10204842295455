import { render, staticRenderFns } from "./DisplayOption.vue?vue&type=template&id=58fbc09d&scoped=true&"
import script from "./DisplayOption.vue?vue&type=script&lang=ts&"
export * from "./DisplayOption.vue?vue&type=script&lang=ts&"
import style0 from "./DisplayOption.vue?vue&type=style&index=0&id=58fbc09d&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58fbc09d",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VCard,VCardText,VCheckbox,VRow,VSelect})


/* vuetify-loader */
import installDirectives from "!../../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import ClickOutside from 'vuetify/lib/directives/click-outside'
installDirectives(component, {ClickOutside})


/* hot reload */
if (module.hot) {
  var api = require("C:\\00_My_office\\80_Work\\ALLIGATE-Pro\\20240918\\alliboard-pro-Release-20240918\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('58fbc09d')) {
      api.createRecord('58fbc09d', component.options)
    } else {
      api.reload('58fbc09d', component.options)
    }
    module.hot.accept("./DisplayOption.vue?vue&type=template&id=58fbc09d&scoped=true&", function () {
      api.rerender('58fbc09d', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/molecules/DisplayOption/DisplayOption.vue"
export default component.exports