import { render, staticRenderFns } from "./InRoomManagementPage.vue?vue&type=template&id=01f112ae&"
import script from "./InRoomManagementPage.vue?vue&type=script&lang=ts&"
export * from "./InRoomManagementPage.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\00_My_office\\80_Work\\ALLIGATE-Pro\\20240918\\alliboard-pro-Release-20240918\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('01f112ae')) {
      api.createRecord('01f112ae', component.options)
    } else {
      api.reload('01f112ae', component.options)
    }
    module.hot.accept("./InRoomManagementPage.vue?vue&type=template&id=01f112ae&", function () {
      api.rerender('01f112ae', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/pages/in-room-management/InRoomManagementPage.vue"
export default component.exports