import { render, staticRenderFns } from "./TbEyeTemplate.vue?vue&type=template&id=089c39f5&scoped=true&"
import script from "./TbEyeTemplate.vue?vue&type=script&lang=ts&"
export * from "./TbEyeTemplate.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "089c39f5",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VCol,VContainer,VIcon,VRow})


/* hot reload */
if (module.hot) {
  var api = require("C:\\00_My_office\\80_Work\\ALLIGATE-Pro\\20240918\\alliboard-pro-Release-20240918\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('089c39f5')) {
      api.createRecord('089c39f5', component.options)
    } else {
      api.reload('089c39f5', component.options)
    }
    module.hot.accept("./TbEyeTemplate.vue?vue&type=template&id=089c39f5&scoped=true&", function () {
      api.rerender('089c39f5', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/templates/TbEyeTemplate/TbEyeTemplate.vue"
export default component.exports