import { render, staticRenderFns } from "./Breadcrumbs.vue?vue&type=template&id=30ae010f&"
import script from "./Breadcrumbs.vue?vue&type=script&lang=ts&"
export * from "./Breadcrumbs.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
installComponents(component, {VBreadcrumbs})


/* hot reload */
if (module.hot) {
  var api = require("C:\\00_My_office\\80_Work\\ALLIGATE-Pro\\20240918\\alliboard-pro-Release-20240918\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('30ae010f')) {
      api.createRecord('30ae010f', component.options)
    } else {
      api.reload('30ae010f', component.options)
    }
    module.hot.accept("./Breadcrumbs.vue?vue&type=template&id=30ae010f&", function () {
      api.rerender('30ae010f', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/molecules/Breadcrumbs/Breadcrumbs.vue"
export default component.exports