import { render, staticRenderFns } from "./Pagination.vue?vue&type=template&id=8560f226&"
import script from "./Pagination.vue?vue&type=script&lang=ts&"
export * from "./Pagination.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VPagination } from 'vuetify/lib/components/VPagination';
installComponents(component, {VPagination})


/* hot reload */
if (module.hot) {
  var api = require("C:\\00_My_office\\80_Work\\ALLIGATE-Pro\\20240918\\alliboard-pro-Release-20240918\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('8560f226')) {
      api.createRecord('8560f226', component.options)
    } else {
      api.reload('8560f226', component.options)
    }
    module.hot.accept("./Pagination.vue?vue&type=template&id=8560f226&", function () {
      api.rerender('8560f226', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/molecules/Pagination/Pagination.vue"
export default component.exports