import { render, staticRenderFns } from "./UserGateTable.vue?vue&type=template&id=5f0bcc5a&"
import script from "./UserGateTable.vue?vue&type=script&lang=ts&"
export * from "./UserGateTable.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VContainer,VDataTable,VDivider,VRow,VSpacer})


/* hot reload */
if (module.hot) {
  var api = require("C:\\00_My_office\\80_Work\\ALLIGATE-Pro\\20240918\\alliboard-pro-Release-20240918\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5f0bcc5a')) {
      api.createRecord('5f0bcc5a', component.options)
    } else {
      api.reload('5f0bcc5a', component.options)
    }
    module.hot.accept("./UserGateTable.vue?vue&type=template&id=5f0bcc5a&", function () {
      api.rerender('5f0bcc5a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/organisms/UserGateTable/UserGateTable.vue"
export default component.exports