import { render, staticRenderFns } from "./SideNavigation.vue?vue&type=template&id=004ddc81&scoped=true&"
import script from "./SideNavigation.vue?vue&type=script&lang=ts&"
export * from "./SideNavigation.vue?vue&type=script&lang=ts&"
import style0 from "./SideNavigation.vue?vue&type=style&index=0&id=004ddc81&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "004ddc81",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
installComponents(component, {VIcon,VList,VListItem,VListItemGroup,VListItemIcon,VListItemTitle,VNavigationDrawer})


/* hot reload */
if (module.hot) {
  var api = require("C:\\00_My_office\\80_Work\\ALLIGATE-Pro\\20240918\\alliboard-pro-Release-20240918\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('004ddc81')) {
      api.createRecord('004ddc81', component.options)
    } else {
      api.reload('004ddc81', component.options)
    }
    module.hot.accept("./SideNavigation.vue?vue&type=template&id=004ddc81&scoped=true&", function () {
      api.rerender('004ddc81', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/organisms/SideNavigation/SideNavigation.vue"
export default component.exports