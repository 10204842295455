import { render, staticRenderFns } from "./UsersTemplate.vue?vue&type=template&id=4491687f&scoped=true&"
import script from "./UsersTemplate.vue?vue&type=script&lang=ts&"
export * from "./UsersTemplate.vue?vue&type=script&lang=ts&"
import style0 from "./UsersTemplate.vue?vue&type=style&index=0&id=4491687f&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4491687f",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAutocomplete,VBtn,VCard,VCardActions,VCardText,VCardTitle,VCol,VContainer,VDialog,VDivider,VList,VListItem,VListItemTitle,VMenu,VProgressLinear,VRow})


/* hot reload */
if (module.hot) {
  var api = require("C:\\00_My_office\\80_Work\\ALLIGATE-Pro\\20240918\\alliboard-pro-Release-20240918\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4491687f')) {
      api.createRecord('4491687f', component.options)
    } else {
      api.reload('4491687f', component.options)
    }
    module.hot.accept("./UsersTemplate.vue?vue&type=template&id=4491687f&scoped=true&", function () {
      api.rerender('4491687f', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/templates/UsersTemplate/UsersTemplate.vue"
export default component.exports